import * as React from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"
import Seo from "../components/SEO"
import {
  Container,
  ContentWithPaddingXl,
} from "../components/Common/Containers"
import { PolicyText } from "../components/Common/PolicyText"
import { AltHeading } from "../components/Common/Headings"
import ScrollToTop from "../components/Common/ScrollToTop"

export const PolicyTextStyled = styled(PolicyText)`
  table {
    ${tw`w-full sm:w-3/5`}
    margin: auto;
    margin-bottom: 40px;
    margin-top: 20px;
    img {
      margin: auto;
      width: 80px;
      max-height: 80px;
    }
  }
  td {
    ${tw`text-center w-1/4`}
  }
`

const TermsOfUse = ({ path, ...rest }) => {
  const { language } = useTranslation().i18n

  const data = useStaticQuery(graphql`
    query($content: String = "gdpr") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              lang
              heading
            }
            html
          }
        }
      }
    }
  `)

  const filteredEdge = data.allMarkdownRemark.edges.find(
    edge => edge.node.frontmatter.lang === language
  )

  const { heading } = filteredEdge.node.frontmatter
  const { html } = filteredEdge.node

  return (
    <>
      <Seo title={heading} />
      <Container id="gdpr">
        <ScrollToTop />

        <ContentWithPaddingXl>
          <AltHeading>{heading}</AltHeading>
          <PolicyTextStyled dangerouslySetInnerHTML={{ __html: html }} />
        </ContentWithPaddingXl>
      </Container>
    </>
  )
}

export default TermsOfUse
